.saddle-1  {background-color: #e13838; color: #fff}
.saddle-2  {background-color: #f5f5f5; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-3  {background-color: #295fcc; color: #fff}
.saddle-4  {background-color: #fff200; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-5  {background-color: #4d801a; color: #fff}

.saddle-6  {background-color: #2e3033; color: #fff200}
.saddle-7  {background-color: #e67300; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-8  {background-color: #ff3399; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-9  {background-color: #33cccc; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-10 {background-color: #800080; color: #fff}

.saddle-11  {background-color: #bfbfbf; color: #ad0000}
.saddle-12  {background-color: #00ff00; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-13  {background-color: #663300; color: #fff}
.saddle-14  {background-color: #800000; color: #fbdc50}
.saddle-15  {background-color: #b0a551; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}

.saddle-16  {background-color: #abc8e4; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-17  {background-color: #132361; color: #fff}
.saddle-18  {background-color: #005a26; color: #fff}
.saddle-19  {background-color: #6396ca; color: #000; text-shadow: 0px 1px 0px rgba(255,255,255,0.1)}
.saddle-20  {background-color: #960031; color: #fff}

.saddle-21  {background-color: #b7afda; color: #000;}
.saddle-22  {background-color: #00336a; color: #fff;}
.saddle-23  {background-color: #b4d8cd; color: #003500;}
.saddle-24  {background-color: #274700; color: #fff;}