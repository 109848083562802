body, html {
    @apply bg-white text-gray-800;
    -webkit-font-smoothing: antialiased;
}

/* FA Icons .. Eck */
.fa-solid {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
}

/*
Color Theming for Properties
*/
@layer base {
    :root {
        --color-aqu: 0 130 153;
        --color-sar: 221 0 49;
        --color-bel: 0 103 74;
        --color-bes: 153 124 67;
        --color-nyra: 4 30 65;
        --color-none: 51 65 85;
        --color-accent: var(--color-none);
        --color-tint: var(--color-accent);
        --heading-font: inherit;
    }
    .theme-aqu { --color-accent: var(--color-aqu) }
    .theme-sar { 
        --heading-font: museo, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        --color-accent: var(--color-sar);
        --color-tint: var(--color-sar);
    }
    .theme-sar .font-heading {
        font-weight: 700;
    }
    .theme-bel { --color-accent: var(--color-bel); --color-tint: var(--color-bel) }
    .theme-bes { --color-accent: var(--color-bes); --color-tint: var(--color-bes) }
    .theme-nyra { --color-accent: var(--color-nyra); --color-tint: var(--color-nyra) }
    .theme-none { --color-accent: var(--color-none); --color-tint: var(--color-none) }
    .theme-sans-heading { --heading-font: bio-sans, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" }

    a:where([href]) {
        color: rgb(var(--color-tint))
    }

    .dark.text-accant, .dark .text-accent, .section-theme-dark .text-accent { color: rgb(var(--color-tint) / 1) }
}

/* We don't use this class and don't want to. See .body-offset */
html .container {
    max-width: 100%;
}

/*
Layout Constraints 
*/
.body-offset {
    @apply mx-auto;
    max-width: 90rem;
}
.body-inset {
    @apply px-5;
}
@screen md {
    .body-inset {
        @apply px-10;
    }
}

.section-inset {
    @apply py-10;
}
@screen lg {
    .section-inset {
        @apply py-20;
    }
}


.prose-offset {
    @apply mx-auto;
    max-width: 52rem;
}


/* Document Semantics */
.format-text {
    h1, h2, h3, h4, h5, h6 {
        font-family: var(--heading-font)
    }
}
.format-text h1 {
    @apply text-4xl font-semibold;
}
.format-text h2 {
    @apply text-3xl font-semibold;
}
.format-text h3 {
    @apply text-xl font-semibold;
}
.format-text h4 {
    @apply text-lg font-semibold;
}
.format-text h5 {
    @apply text-sm font-semibold uppercase;
    letter-spacing: 0.25em;
}
.format-text :is(h1, p, ul, ol, dl, table):not(:last-child) {
    @apply mb-4;
}
.format-text :is(h2, h3, h4, h5):not(:last-child) {
    @apply mb-2;
}
.format-text :is(h2, h3, h4, h5):not(:first-child) {
    @apply mt-6;
}
.format-text li {
    @apply ml-7 pl-1;
    display: list-item;
}
.format-text ul li {
    list-style-type: square;
}
.format-text ul li li {
    list-style-type: "-";
}
.format-text ol li {
    list-style-type: decimal;
}
.format-text ol li li {
    list-style-type: lower-alpha;
}
.format-text ol li li li {
    list-style-type: lower-roman;
}
.format-text li:not(:last-child) {
    @apply mb-2;
}
.format-text li :is(ul, ol, dl) {
    @apply mt-3;
}
.format-text table {
    @apply text-sm;
    width: 100% !important;
    overflow-x: auto
}
.format-text th {
    @apply text-sm;
}
.format-text tr > * {
    @apply py-2 pr-2 text-left;
}
.format-text tr > :last-child:not(:first-child) {
    @apply text-right pr-0;
}
.format-text tbody tr:not(:first-child) {
    @apply border-t border-solid border-zinc-300;
}
@screen lg {
    .format-text h1 {
        @apply text-5xl;
    }
    .format-text h2 {
        @apply text-4xl;
    }
    .format-text h3 {
        @apply text-3xl;
    }
    .format-text h4 {
        @apply text-xl;
    }
    .format-text h5 {
        @apply text-lg tracking-normal;
        text-transform: none;
    }
    .format-text :is(h1, p, ul, ol, dl):not(:last-child) {
        @apply mb-9;
    }
    .format-text :is(h2, h3, h4, h5):not(:last-child) {
        @apply mb-4;
    }
    .format-text :is(h2, h3, h4, h5):not(:first-child) {
        @apply mt-9;
    }
    .format-text li {
        @apply pl-2;
    }
    .format-text li:not(:last-child) {
        @apply mb-2;
    }
    .format-text li :is(ul, ol, dl) {
        @apply mt-3;
    }
    .format-text table {
        @apply text-base;
    }
}

/* Link Utilities */
.underlink a:hover,
a.underlink:hover {
    @apply underline;
}
.no-underlink a:hover,
a.no-underlink:hover {
    text-decoration: none;
}
.blend-links a {
    color: inherit;
}

/* Special Effects */
.glint-top {
    border-top: 1px solid;
    border-image: 
    linear-gradient(
    to right, 
    rgba(255 255 255 / 0), 
    rgba(255 255 255 / 0.25),
    rgba(255 255 255 / 0)
    ) 100% 1;
}

.glow-top {
    background-image: 
    radial-gradient(
    ellipse 50% 50% at top, 
    rgba(255, 255, 255, 0.075), 
    rgba(255, 255, 255, 0)
    );
}

/* Track-specific logo adjustements */
.theme-aqu .logo-size {
    height: 1.625rem;
}
.theme-bel .logo-size {
    height: 2.5rem;
}
.theme-sar .logo-size {
    height: 2.5rem;
    position: relative;
    top: -.25rem;
}
.theme-bes .logo-size {
    height: 2.5rem;
}

/* Mobile Menu */
[data-navigator-trigger] {
    path {
        transition: 0.2s transform;
        transform-origin: center;
    }
    &[data-open="true"] path {
        &:nth-child(1) {
            transform: rotate(45deg) translateY(5px);
        }
        &:nth-child(2) {
            transform: rotate(-45deg) translateY(-5px);
        }
    }
}

/* Megamenu */
[data-megamenu-overlay] {
    @apply fixed left-0 top-0 right-0 bottom-0 z-40 opacity-0 transition-all backdrop-blur-lg;
    background: rgba(4, 11, 21, 0.784);
    &[data-open="true"] {
        opacity: 1;
    }
    &[data-open="false"] {
        @apply pointer-events-none;
    }
}

/* Home Page */
.home-slide-height {
    min-height: min(960px, max(calc(100vh-8.5rem), 32rem));
}
.cms-toolbar-expanded .home-slide-height {
    min-height: min(960px, max(calc(100vh-8.5rem-2.875rem), 32rem));
}
.home-slide-tab-position {
    position: sticky;
}
.home-hero-text {
    @apply text-2xl;
}
.home-caption-text {
    @apply text-sm;
}
@media screen(lg) {
    .home-hero-text {
        font-size: clamp(1.75rem, 5vh + -.5rem, 3rem);
        line-height: 1.1;
    }
    .home-caption-text {
        font-size: clamp(0.875rem, 3vh + -.5rem, 1.125rem);
        line-height: 1.5;
    }
}

/* Calendar */
.bg-empty-cell {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAAXNSR0IArs4c6QAAABFJREFUCB1jYMANjHFJDU4JAIw/AWavEhD2AAAAAElFTkSuQmCC) repeat
}

/* Dialogs */
dialog {
    animation: fade-out 0.2s ease-out;
}
dialog[open] {
    animation: fade-in 0.4s ease-out;
}
dialog[open]::backdrop {
    animation: backdrop-fade-in 0.4s ease-out forwards;
}
@keyframes fade-in {
    0% {
        opacity: 0;
        transform: scale(0.95);
        display: none;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        display: block;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
        transform: scale(1);
        display: block;
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
        display: none;
    }
}
@keyframes backdrop-fade-in {
    0% {
        background-color: rgb(0 0 0 / 0%);
    }
    
    100% {
        background-color: rgb(0 0 0 / 50%);
    }
}

/* Race Day Live / Video Player */
.pip-position[data-pip="true"] {
    @apply rounded-md top-12;
    position: fixed;
    right: 3rem;
    z-index: 100;
    width: calc(33% - 5rem);
    aspect-ratio: 16/9;
    transition: none;
}
@media screen and (min-width: 96rem) {
    .pip-position[data-pip="true"] {
        right: calc((100vw - 96rem) / 2 + 3rem);
        width: 26.5rem;
    }
}

/* HTMX Animations */
.htmx-swapping .swap\:fade {
    /* transform: translateX(-4rem); */
}
.htmx-swapping .swap\:fade,
.htmx-added.swap\:fade,
.htmx-settling .swap\:fade {
    opacity: 0;
}
.htmx-request .swap\:fade,
.htmx-request.swap\:fade {
    opacity: 0.667;
}
.htmx-settling .swap\:fade {
    /* transform: translateX(4rem); */
}


/* Grid Layout Utilities */
@media not all and screen(lg) {
    .grid-mobile-reverse[class*="grid-cols-2"] {
        > :nth-child(1) { @apply order-2 }
        > :nth-child(2) { @apply order-1 }
    }
    .grid-mobile-reverse[class*="grid-cols-3"] {
        > :nth-child(1) { @apply order-3 }
        > :nth-child(2) { @apply order-2 }
        > :nth-child(3) { @apply order-1 }
    }
    .grid-mobile-reverse[class*="grid-cols-4"] {
        > :nth-child(1) { @apply order-4 }
        > :nth-child(2) { @apply order-3 }
        > :nth-child(3) { @apply order-2 }
        > :nth-child(4) { @apply order-1 }
    }
    .grid-mobile-reverse[class*="grid-cols-5"] {
        > :nth-child(1) { @apply order-5 }
        > :nth-child(2) { @apply order-4 }
        > :nth-child(3) { @apply order-3 }
        > :nth-child(4) { @apply order-2 }
        > :nth-child(5) { @apply order-1 }
    }
}


/* Forms */
.format-form {
    label {
        @apply block font-semibold;
    }
    input:not([type="button"], [type="submit"]) {
        @apply text-base py-2 px-3 rounded-md;
    }
}


/* Scratches and Changes Embeds */
.format-scratches-changes {
    td, .Text {
        @apply font-body;
    }
}

/* Responsive YouTube */
iframe[src*='www.youtube.com'] {
    width: 100%;
    aspect-ratio: 16 / 9;
    height: auto;
}


/* video.js Adjustments */
.video-js.rounded-md {
    video {
        border-radius: 6px;
    }
}

/* Basic Lightbox */
/* https://raw.githubusercontent.com/electerious/basicLightbox/refs/heads/master/dist/basicLightbox.min.css */
.basicLightbox{position:fixed;display:flex;justify-content:center;align-items:center;top:0;left:0;width:100%;height:100vh;background:rgba(0,0,0,.8);opacity:.01;transition:opacity .4s ease;z-index:1000;will-change:opacity}.basicLightbox--visible{opacity:1}.basicLightbox__placeholder{max-width:100%;transform:scale(.9);transition:transform .4s ease;z-index:1;will-change:transform}.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{display:block;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;max-width:95%;max-height:95%}.basicLightbox__placeholder>iframe:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{pointer-events:auto}.basicLightbox__placeholder>img:first-child:last-child,.basicLightbox__placeholder>video:first-child:last-child{width:auto;height:auto}.basicLightbox--iframe .basicLightbox__placeholder,.basicLightbox--img .basicLightbox__placeholder,.basicLightbox--video .basicLightbox__placeholder{width:100%;height:100%;pointer-events:none}.basicLightbox--visible .basicLightbox__placeholder{transform:scale(1)}