/* Import typekit CSS */
@import url("https://use.typekit.net/fpt0ksg.css");

/* Tailwind CSS directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import custom CSS files */
@import "./base.css";
@import "./saddles.css";
@import "./video.css";