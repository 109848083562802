/* Customizations to swiper carousels */
.swiper-bullets-below {
    --swiper-pagination-top: auto;
    --swiper-pagination-bottom: 0;
}
.swiper-white-bullets {
    --swiper-theme-color: white;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-bullet-inactive-opacity: 0.33;
}

.tiny-swiper {
    position: relative;
    padding-bottom: 1rem;
    --swiper-pagination-top: auto;
    --swiper-pagination-bottom: 0;
    --swiper-theme-color: black;
    --swiper-pagination-bullet-size: 5px;
    .swiper-pagination {
        text-align: left !important;
        width: auto !important;
    }
    .swiper-pagination-bullet {
        margin: 0 6px 0 0 !important;
    }
}

.image-swiper, .item-swiper, .swiper-theme {
    --swiper-theme-color: rgb(var(--color-accent));
    --swiper-navigation-size: 1.25rem;
    &.swiper-dark-mode {
        --swiper-theme-color: white;
        --swiper-pagination-bullet-inactive-color: white;
        --swiper-pagination-bullet-inactive-opacity: 0.33;
    }
}
[data-home-slider-root] .swiper-slide,
.item-swiper .swiper-slide {
    height: auto;
}